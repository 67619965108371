<template>
  <div>
   <div class="form-group">
                    <div class="input-icon input-icon-right">
                      <input
                        type="text"
                        class="form-control"
                        @input="filterData"
                        v-model="filter"
                        placeholder="Search by client name"
                      />
                      <span @click="filterData"
                        ><i class="flaticon2-search-1 icon-md"></i
                      ></span>
                    </div>
                  </div>
    <img src="../../../../public/loaderB.gif" alt="" width="100%" height="10px" class="mb-5" v-if="toggled">
    <span class="switch switch-icon switch-sm float-right clearfix" >
      
                {{$t('Auto Refresh')}}
                <label>
                  <input
                    type="checkbox"
                    name="select"
                    v-model="toggled"
                    @click="setToggle"
                    
                    
                  />
                  <span></span>
                </label>
              </span>

              <div class="">

              </div>

             
              
    <b-table
      :items="table.items"
      :fields="$t('clan')=='en'?table.fields:tableAR.fields"
      :responsive="true"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
    >

    <template #empty>
              <div v-if="isBusy" class="text-center ">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                  class="spinnerClass"
                ></b-spinner>
              </div>
              <p
                v-else

                class="text-center muted mb-0 mt-2"
                style="font-size: 13px"
              >
                {{ $t('There are no records to show') }}
              </p>
            </template>

    <template #cell(customer)="row">
        <div class="" v-if="row.item.customer !== null">
          <span class="trigger" @click="navigate(row.item.customer)">{{row.item.customer.name}}</span>
          
        </div>
      </template>
      <template #cell(actions)="row">
        <div class="">
          <button @click.prevent="viewOrder(row.item.id)" class="">
            <i class="far fas fa-eye"></i>
            {{$t('view')}}
          </button>
        </div>
      </template>

      <template #cell(zone_id)="row">
          <template v-for="zone in row.item.client.zones" >
            <span v-if="zone.id==row.item.zone_id" :key="zone.id" >
              {{zone.name_ar}}              
            </span>
          </template>


      </template>

      <template #cell(status)="row">
        <span
          class="label font-weight-bold label-lg label-light-secondary text-dark label-inline"
          v-if="row.item.status === 'Created'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-primary label-inline"
          v-if="row.item.status === 'Started'"
          >{{ $t(row.item.status) }}</span
        >
         <span
          class="label font-weight-bold label-lg label-light-danger label-inline"
          v-if="row.item.status === 'Failed'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-danger label-inline"
          v-if="row.item.status === 'Cancelled'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-success label-inline"
          v-if="row.item.status === 'Successful'"
          >{{ $t(row.item.status) }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-warning label-inline"
          style="width:70px"
          v-if="row.item.status === 'Unassigned'"
          
          >{{ $t(row.item.status) }}</span
        >
      </template>

      <template #cell(isAddressConfirmed)="row">
        <div v-if="row.item.isAddressConfirmed == '1'" >
          
          <b-badge variant="info" style="font-size:10px">{{$t('confirmed')}}<i :class="$t('clan')==='en'?'fas fa-check-circle text-light ml-2':'fas fa-check-circle text-light mr-2'" style="font-size:12px"></i></b-badge>
        </div>
        <div v-else>
          {{$t('No')}}
        </div>
      </template>

      <template #cell(vehicle_type)="row">
        <div v-if="row.item.vehicle_type == '0'" >
          
          <span>Car</span>
        </div>
        <div v-else>
          <span>Scooter</span>
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <!--<b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="left"
      size="lg"
      class="my-0"
      @click.native="getData"
    ></b-pagination>-->
    <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr">
    <li class="page-item "><a class="page-link"  @click="prev">{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link"  @click="next">{{$t('Next')}}</a></li>
  </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//import image from '../../../../public/verefied.png'
//import image from '../../../../public/loaderA.gif'

export default {
  name: "OrdersTable",
  data: function () {
    return {
      isBusy:'',
      filter:'',
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      firstPage:1,
      lastPage:'',
      toggled:false
    };
  },
  async mounted() {
    this.isBusy = true
    await this.$store.dispatch("orders/loadAll").then((res) => {
      this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
      this.isBusy = false
    });
  },
  created(){
    if(this.toggled===true){
      //setTimeout(this.refresh, 5000);
      setInterval(this.refresh, 5000)
    }
    
    

  },
  computed: {
    ...mapGetters({ table: "orders/table" }),
    ...mapGetters({ tableAR: "orders/tableAR" }),
    
  },

  methods: {
    navigate(customer){
      this.$router.push({ name: "ViewCustomer", params: { id: customer.id, customer:customer }});
    },
    filterData(){
      this.$store.dispatch('orders/search',this.filter).then((res)=>{
        this.currentPage = res.data.current_page
        this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
      })
    },
    async refresh(){
      if(this.toggled === true){
         //window.location.reload()
         console.log("Updated")
         await this.$store.dispatch("orders/loadAll").then((res) => {
      this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
    });
         
        
      }
      
       

      
      

    },
    setToggle(){
      
      if(this.toggled === true){
        this.toggled = false;
        
        
        //setTimeout(this.refresh, 5000);

      }
      else if(this.toggled === false){
        this.toggled = true
       setInterval(this.refresh, 5000);
        
      }
      

    },
    next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      
      this.getData()

    },
    prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
      
      this.getData()

    },
    async getData() {
      this.isBusy = true
      await this.$store.dispatch("orders/loadPage", this.currentPage);
      this.isBusy = false
    },
    viewOrder(id) {
      this.$router.push({ name: "ViewOrder", params: { id: id } });
    },
  },
};
</script>
<style lang="css" scoped>

.icon-md:hover {
  
  color: #4f2c85 !important;
  cursor: pointer;
}
.trigger:hover{
  cursor: pointer;
  color: rgb(26, 87, 167);
}
</style>