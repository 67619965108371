<template>
  <div>
    <template>
      <div :dir="$t('clan')=='ar'?'rtl':'ltr'">
        <div class="rows">
          <div class="col-md-12">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">{{$t('Orders')}}</h3>
                </div>
                <span class="add">
                 
                    <b-button variant="warning" @click.prevent="showModal()"
                      ><i class="fas fa-plus-circle text-white mr-2"  v-if="$t('clan')=='en'"></i
                      >
                      <i class="fas fa-plus-circle text-white ml-1"  v-else></i
                      >
                      {{$t('New')}}</b-button
                    >
                </span>

                <b-modal
                    id="modal-1"
                    modal-class="modal"
                    ref="my-modal"
                    centered
                    hide-header
                    hide-footer
                    size="lg"        
                               
                    
                  >
                  <div class="row" v-if="step == 1">
                      <div class="col-sm-12 mt-3  text-right " @click="hideModal"><i class="fas fa-times mr-5 cl"></i></div>

                    <div class="col-sm-12 text-center">
                      <h3 style="font-weight:600">Client Informations</h3>
                      </div>
                
                  <!--Field 1 Client-->
                  <div class="col-sm-12 mb-5"></div>
                  <div :class="$t('clan')==='ar'?'col-sm-12  mb-5 text-right':'col-sm-12  mb-5'" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <label class="labe ">{{$t('Client')}}<span class="text-danger ml-1" style="font-size:15px; font-weight:bold">*</span></label>
                    <!--<select
                            class="form-control form-control-lg form-control-solid"
                            v-model="client"
                            
                          >
                            <template v-for="client in clients">
                              <option
                                :key="client.id"
                                :value="client"
                                :selected="
                                  client == client ? true : false
                                "
                              >
                                {{ client.name }}
                              </option>
                            </template>
                          </select>-->
                          <vue-select
                            name="clients"
                            label="name"
                            :key="'clients-field'"
                            :options="clients"
                            :closeOnSelect="true"
                             :searchable="true"
                             v-model="client"
                             @input="getclientData"
                          ></vue-select>
                  </div>
                   <!--End Field 1 Client-->


                  <!-- <div :class="$t('clan')==='ar'?'col-sm-6   text-right':'col-sm-6  '" :dir="$t('clan')==='ar'?'rtl':'ltr'" >
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Customer')}}<span class="text-danger " style="font-size:15px; font-weight:bold">*</span></label>
                      <b-form-input
                      
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                       v-model="order.customer_name"
                       
                      />
                      
                    </div>
                  </div>

                  <div :class="$t('clan')==='ar'?'col-sm-6  text-right':'col-sm-6 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('phone')}}<span class="text-danger " style="font-size:15px; font-weight:bold">*</span></label>
                      <b-form-input
                     :class="{
                              'form-input--error': $v.order.phone.$error,
                            }"
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                       v-model="order.phone"
                      />
                    </div>
                  </div>

                  <div :class="$t('clan')==='ar'?'col-sm-6 text-right':'col-sm-6 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Amount')}}<span class="text-danger " style="font-size:15px; font-weight:bold">*</span></label>
                      <b-form-input
                      
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                       v-model="order.amount"
                       
                      />
                    </div>
                  </div>


                  <div :class="$t('clan')==='ar'?'col-sm-6 text-right':'col-sm-6 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Notes')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        
                       v-model="order.notes"
                      />
                      
                    </div>
                  </div>


                  <div :class="$t('clan')==='ar'?'col-sm-6 text-right':'col-sm-6 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class='labe'>{{$t('Zone')}} <span class="text-danger " style="font-size:15px; font-weight:bold">*</span></label>
                      <select
                            class="form-control form-control-lg form-control-solid"
                            v-model="zone"
                            :class="{
                              'form-input--error': $v.order.zone.$error,
                            }"
                          >
                            <template v-for="zone in client.zones">
                              <option
                                :key="zone.id"
                                :value="zone"
                                :selected="
                                  zone == zone ? true : false
                                "
                              >
                                {{ zone.name_ar }}
                              </option>
                            </template>
                          </select>
                      
                    </div>
                  </div>


                  <div :class="$t('clan')==='ar'?'col-sm-6 text-right mt-1':'col-sm-6 mt-1'" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                       <div class="form-group">
                        <label class="labe">{{$t('Date')}}</label>
              
                        <datetime v-model="order.date"   type='time' title='Select the time' :use12-hour="true" class="form-control form-control-solid form-control-lg ">
                        
                        </datetime>
             
                      </div>
                  </div>


                  <div class="col-sm-12" v-if="showDate !=='NaN-undefined-NaN NaN:NaN AM' && updated === true">
                      <div class="alert alert-custom alert-light-primary fade show mb-5" role="alert">
                        <div class="alert-icon"><i class="fas fa-calendar-alt"></i></div>
                        <div class="alert-text" :dir="$t('clan')==='ar'?'rtl':'ltr'">{{$t('Yselected')}} {{showDate}}</div>
                        <div class="alert-close">
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true"><i class="ki ki-close"></i></span>
                            </button>
                        </div>
                      </div>
                  </div>

                  
                   
                  <div :class="$t('clan')==='ar'?'col-sm-12 text-right':'col-sm-12 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Street Name')}}<span class="text-danger " style="font-size:15px; font-weight:bold">*</span></label>
                      <b-form-input
                      
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                       v-model="order.street_name"
                      />
                    </div>
                  </div>
                  


                 
                  <div :class="$t('clan')==='ar'?'col-sm-4 text-right':'col-sm-4 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Area')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        
                       v-model="order.area"
                      />
                      
                    </div>
                  </div>
                  


                 
                  <div :class="$t('clan')==='ar'?'col-sm-4 text-right':'col-sm-4 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Building')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        
                        v-model="order.building"
                       
                      />
                      
                    </div>
                  </div>
                 
                  

                 
                  <div :class="$t('clan')==='ar'?'col-sm-4 text-right':'col-sm-4 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Floor')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        
                        v-model="order.floor"
                      />
                    </div>
                  </div> -->
                  

                  
                  <!-- <div :class="$t('clan')==='ar'?'col-sm-6 text-right':'col-sm-6 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Longitude')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        v-model="order.long"
                        
                      />
                      
                    </div>
                  </div>
              


                 
                  <div :class="$t('clan')==='ar'?'col-sm-6 text-right':'col-sm-6 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Latitude')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        v-model="order.lat"
                        
                      />
                      
                    </div>
                  </div> -->
                 

                  <!--Field 14 Street Name-->
                 <!-- <div :class="$t('clan')==='ar'?'col-sm-6 text-right':'col-sm-6 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Street Name')}}</label>
                      
                      
                      <input list="browsers" name="browser" id="browser" @input="search" @blur="select"
                       class="form-control form-control-solid form-control-lg form__input" >
                      <datalist id="browsers" >
                        <option v-for="street in streets" :key="street.id" :value="street.name" >{{street.name}}</option>
                      </datalist>
                    </div>
                  </div>-->
                  <!--End Field 14 Street Name-->

                  <!--Field 14 Street Name-->
                 

                  

                  
                  <!--End Field 14 Street Name-->
                
                    <!--End Begin Map Col-->

                  <!--Begin Radio pic select Col-->
                  
                  <div class="col-sm-12">
                      <div
                      class="form-group"
                      
                    >
                    
                      <div class="radioContainer mt-5">
                        <div :class="order.vehicle_type!=='0'?'radio':'radioSel'" @click="setType('0')">
                          <img src="../../../../public/media/types/car.png"  style="width:70px"/>
                        </div>
                        <div :class="order.vehicle_type!=='1'?'radio':'radioSel'" @click="setType('1')">
                          <img src="../../../../public/media/types/scooter.png" style="width:70px"/>
                        </div>
                      </div>
                      
                      
                    </div>
                  </div>
                  <!--End Radio pic select Col-->
            </div>
            <div class="row" v-if="step ==2">
              <div class="col-sm-12 mt-3  text-right " @click="hideModal"><i class="fas fa-times mr-5 cl"></i></div>

              <div class="col-sm-12 text-center mb-4">
                      <h3 style="font-weight:600">Pickup Address</h3>
                      </div>

              <div class="col-xl-12 mb-4">
                  <div>

    <vue-select
                            name="drivers"
                            label="description"
                            :key="'drivers-field'"
                            :options="searchResults"
                            :closeOnSelect="true"
                            @search="fetchOptions"  
                            @input="selectAdress"
                          ></vue-select>
  </div>
                </div>

                  <!--Begin Map Col-->
                  <div class="col-xl-12"  >
                        <div class="location mb-5">
                          <template>
                            <GmapMap
                              style="width: 100%; height: 40vh; margin: auto"
                              :center="{
                                lat: lat,
                                lng: long,
                              }"
                              :zoom="zoom"
                              @click="handleMapClick"
                            >
                              
                            
                              <GmapMarker
                                
                                :clickable="true"
                                :draggable="true"
                              
                                
                                :position="{
                                  lat: parseFloat(this.order.lat),
                                  lng: parseFloat(this.order.long),
                                }"
                                @drag="handleMarkerDrag"
                              />

                            </GmapMap>
                          </template>
                      </div>
                    </div>

            </div>

            <div class="row" v-if="step ==3">
              <div class="col-sm-12 mt-3   text-right " @click="hideModal"><i class="fas fa-times mr-5 cl"></i></div>
                    

                    <div class="col-sm-12 text-center mb-4">
                      <h3 style="font-weight:600">Delivery Address</h3>
                      </div>

                      <div class="col-xl-12 mb-4">
                  <div>

    <vue-select
                            name="drivers"
                            label="description"
                            :key="'drivers-field'"
                            :options="searchResultsDelivery"
                            :closeOnSelect="true"
                            @search="fetchOptions"  
                            @input="selectAdress"
                          ></vue-select>
  </div>
                </div>

                      <div class="col-xl-12"  >
                        <div class="location mb-5">
                          <template>
                            <GmapMap
                              style="width: 100%; height: 40vh; margin: auto"
                              :center="{
                                lat: dlat,
                                lng: dlong,
                              }"
                              :zoom="zoom"
                              @click="handleMapClick"
                            >
                              
                            
                              <GmapMarker
                                
                                :clickable="true"
                                :draggable="true"
                              
                                
                                :position="{
                                  lat: parseFloat(this.order.delivery_lat),
                                  lng: parseFloat(this.order.delivery_long),
                                }"
                                @drag="handleMarkerDrag"
                              />

                            </GmapMap>
                          </template>
                      </div>
                    </div>
            </div>


            <div class="row" v-if="step ==4">
              <div class="col-sm-12 mt-3 mb-4  text-right " @click="hideModal"><i class="fas fa-times mr-5 cl"></i></div>
                    

                    <div class="col-sm-12 text-center">
                      <h3 style="font-weight:600">Others</h3>
                      </div>

                       <div :class="$t('clan')==='ar'?'col-sm-12 text-right':'col-sm-12 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <b-form-checkbox
                      size="sm"
                      id="checkbox-1"
                      v-model="order.overitevalue"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                      
                      
                    >
                    <label class="labe">{{$t('OverriteFees')}}</label>
                    </b-form-checkbox>
                      
                      
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        :disabled="order.overitevalue==='1'?false:true"
                        v-model="order.overitefees"
                      />
                    </div>
                  </div>

                  <div :class="$t('clan')==='ar'?'col-sm-12 text-right':'col-sm-12 '" v-if="client.email !=='general@vaiila.com'" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class='labe'>{{$t('Zone')}}</label>
                      <!--<select
                            class="form-control form-control-lg form-control-solid"
                            v-model="zone"
                            
                          >
                            <template v-for="zone in client.zones">
                              <option
                                :key="zone.id"
                                :value="zone"
                                :selected="
                                  zone == zone ? true : false
                                "
                              >
                                {{ zone.name_ar }}
                              </option>
                            </template>
                          </select>-->

                          <vue-select
                            
                            label="name_ar"
                            :key="'clients-field'"
                            :options="client.zones"
                            :closeOnSelect="true"
                             :searchable="true"
                             v-model="zone"
                             
                          ></vue-select>
                      
                    </div>
                  </div>


                  <div :class="$t('clan')==='ar'?'col-sm-12 text-right':'col-sm-12 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class='labe'>{{$t('SelectTarrif')}}</label>
                      <select
              class="form-control form-control-lg form-control-solid"
              v-model="order.selected_tarrif"
              :disabled="order.overitevalue==='1'?true:false"
            >
              <template v-for="tarrif in tarrifs">
                <option
                  :key="tarrif.id"
                  :value="tarrif.id"
                  :selected="tarrif.id == order.selected_tarrif ? true : false"
                >
                  {{ tarrif.tarrif_name }}
                </option>
              </template>
            </select>
                      
                    </div>
                  </div>


                  <div :class="$t('clan')==='ar'?'col-sm-12  text-right':'col-sm-12 '" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('phone')}}</label>
                      <b-form-input
                     :class="{
                              'form-input--error': $v.order.phone.$invalid,
                            }"
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                       v-model="order.phone"
                      />
                    </div>
                    
                  </div>

                  <div :class="$t('clan')==='ar'?'col-sm-12  text-right':'col-sm-12 '"  v-if="client.email ==='general@vaiila.com'" :dir="$t('clan')==='ar'?'rtl':'ltr'">
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Client')}}</label>
                      <b-form-input
                     
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                       v-model="order.client"
                      />
                    </div>
                  </div>
            </div>
            <!--End Modal Content Row-->
                         
                         

                        
                    <!--Modal Footer Buttons-->  
              <div class="buttons text-center mb-5 ">
                  <b-button variant="white" class="mr-3 h-50px w-120px  bg-secondary"  @click="step!==1?stepBack():hideModal()"
                    >{{step!==1?'Back':'Cancel'}}</b-button
                  >
                  <b-button variant="primary" class="h-50px w-120px" :disabled="client == ''?true : false || ($v.order.phone.$invalid && step ===4?true:false)"  @click="step!==4?stepNext():submit()">{{step!==4?'Next':'Create'}}</b-button>
                </div>
              <div class="mb-5 mt-5  h-2px"></div>
              <!--End Modal Footer Buttons--> 
        </b-modal>
              <!--End Modal-->

              
              </div>
              <div class="card-body">
                <div>
                  <OrdersTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
 

<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import OrdersTable from "@/view/pages/Orders/OrdersTable.vue";
import { required,minLength,maxLength } from "vuelidate/lib/validators";


export default {
  name: 'Orders',

  components: { OrdersTable },

  data() {
    return {
      step:1,
      //-----------------------------------
      //toggled:false,
      tarrifs:[],
      location: '',
      searchResults: [],
      searchResultsDelivery:[],
      service: null,
      geo:null,
      zoom:7,
      lat:31.963158,
      long:35.930359,
      dlat:31.963158,
      dlong:35.930359,

      clients:[],
      client:'',
      zone:'',
      order:{
        client:'',
        client_name:'',
        client_id:'',
        customer_name:null,
        phone:'',
        amount:null,
        notes:null,
        zone:'',
        date:'',
        street_name:null,
        area:null,
        building:null,
        floor:null,
        overitevalue:'0',
        overitefees:0,
        long:null,
        lat:null,
        delivery_long:null,
        delivery_lat:null,
        vehicle_type:'0',
        selected_tarrif:'',
        //fees:0
      },
      searchingvalue:'',
      selectedStreet:'',
      updated:false,
      dumbDate:'',
      showDate:new Date(),
      timer:'',
      //sizes: ['Small', 'Medium', 'Large', 'Extra Large'],
      
      
    }
  },
  metaInfo () {
      return {
        script: [{
          src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCYiD6NQ1pH2_-hCNDaxgxR_7E8sEQGtSo&libraries=places`,
          async: true,
          defer: true,
          callback: () => this.MapsInit() // will declare it in methods
        }]
      }
    },
  validations:{
    order:{
      phone:{
        required,
        minLength:minLength(10),
        maxLength:maxLength(10)
      }
     
    }
  },
  async mounted() {
   
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Orders", route: "/admin/orders" }]);
    this.order.lat=31.963158
    this.order.long=35.930359
    this.order.delivery_lat=31.963158
    this.order.delivery_long=35.930359

    // this.$store.dispatch("orders/orderAdmin", this.order);
     await this.$store.dispatch("queues/fetchCreateData").then((res)=>{
      //console.log(res.clients)
      this.clients = res.clients
      //console.log(this.clients)
    });
    var tarrifs = await this.$store.dispatch('clientTarrif/loadAll')
    this.tarrifs = tarrifs.data
  },
  updated(){
    //console.log(this.client)
    //console.log(this.zone)
    /*this.updated=true
    const monthNames = ["1", "2", "3", "4", "5", "6",
                          "7", "8", "9", "10", "11", "12"
                        ];


    var date = new Date(this.order.date)
    
    //console.log(date)
    var pmAm =''
    var chours=date.getHours()
    var horus=''

     // console.log(chours<12)
      if(chours>12){
        horus = chours - 12
        pmAm = 'PM'
      }else{
        horus = chours
        pmAm ='AM'
      }
      if(chours ==12){
        pmAm ='PM'
      }
      if(chours ==0){
        horus=12
        pmAm ='AM'
      }

       this.showDate = date.getFullYear() + '-' + monthNames[date.getUTCMonth()] +'-' + date.getUTCDate() + ' '+ horus+':' +(date.getUTCMinutes()<10?'0':'')+ date.getUTCMinutes() +' '+ pmAm
*/
},
  /*created(){
    if(this.toggled===true){
      setTimeout(this.refresh, 5000);
    }
    
    

  },*/
   watch: {
      location (newValue) {
        if (newValue) {
          this.service.getPlacePredictions({
            componentRestrictions:{country:'Jo'},
            input: this.location,
            types: []
          }, this.displaySuggestions)
        }
        
      }
    },
  methods:{
    getclientData(e){
      //console.log(e)
      this.order.selected_tarrif = e.selected_tarrif
      //console.log(e)
     this.order.long =parseFloat(e.long)
        this.order.lat = parseFloat(e.lat)
        this.long =parseFloat(e.long)
        this.lat =parseFloat(e.lat)
        this.zoom = 18
    },
    stepNext(){
      //console.log(this.client.email)
      /*if(this.client.email == 'general@vaiila.com'){
        console.log(this.client)
        this.order.long =parseFloat(this.client.long)
        this.order.lat = parseFloat(this.client.lat)
        this.long =parseFloat(this.client.long)
        this.lat =parseFloat(this.client.lat)
        this.zoom = 18
      }*/
      this.step = this.step + 1
    },
    stepBack(){
      this.step = this.step - 1
    },
    selectAdress(value){
      //console.log(value)
      this.$store.dispatch('orders/getPlaceDetails',value).then((res)=>{
        //console.log(res)
        if(this.step ===2){
          this.order.long = res.location.lng;
        this.order.lat = res.location.lat
        this.long = res.location.lng;
        this.lat = res.location.lat
        this.zoom = 18
        }

        if(this.step ===3){
          this.order.delivery_long = res.location.lng;
        this.order.delivery_lat = res.location.lat
        this.dlong = res.location.lng;
        this.dlat = res.location.lat
        this.zoom = 18
        }
        
      })
    },
    fetchOptions (search) {
      this.location = search
      //console.log(search)
  //  ... do some asynchronous stuff!  
},
    
    MapsInit () {
        this.service = new window.google.maps.places.AutocompleteService()
      },
      displaySuggestions (predictions, status) {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          this.searchResults = []
          return
        }
        if(this.step ==2){
          this.searchResults = predictions.map(prediction => prediction) 
        }
        
        if(this.step ==3){
          this.searchResultsDelivery = predictions.map(prediction => prediction) 
          
        }
        //console.log(this.searchResults)
      },

        
    async submit(){
//this.order.delivery_lat=31.963158
    //this.order.delivery_long=35.930359

      this.order.client_id = this.client.id
      this.order.client_name = this.client.name
      this.order.zone =  this.zone.id
      this.order.long = this.order.long.toString()
      this.order.lat = this.order.lat.toString()
      this.order.delivery_long = this.order.delivery_long.toString()
      this.order.delivery_lat = this.order.delivery_lat.toString()
      if(this.order.delivery_lat === '31.963158' && this.order.delivery_long === '35.930359' && this.client.email !== 'general@vaiila.com' ){
        this.order.delivery_lat = null
        this.order.delivery_long = null
      }
      
      this.order.overitefees = parseFloat(this.order.overitefees)
     
      


  /*const monthNames = ["1", "2", "3", "4", "5", "6",
                          "7", "8", "9", "10", "11", "12"
                        ];
    var date = new Date(this.order.date)
    //console.log(date)
    

      //this.order.date = date.getFullYear() + '-' + monthNames[date.getUTCMonth()] +'-' + date.getUTCDate()

      this.order.date = date.getFullYear() + '-' + (parseInt(monthNames[date.getUTCMonth()])<10?'0'+monthNames[date.getUTCMonth()]:monthNames[date.getUTCMonth()]) +'-' +
     (date.getUTCDate()<10?'0'+date.getUTCDate():date.getUTCDate())

      if(this.order.date === 'NaN-undefined-NaN'){
        this.order.date = null
      }*/

     await this.$store.dispatch("orders/orderAdmin", this.order);
      //console.log(this.order)
      this.order.delivery_lat=31.963158
    this.order.delivery_long=35.930359
      this.hideModal()
      //console.log(this.order)
    },
 /* search(e){
   console.log(e.target.value)
   this.searchingvalue = e.target.value
    const url = "https://maps.googleapis.com/maps/api/place/details/json?key=AIzaSyCYiD6NQ1pH2_-hCNDaxgxR_7E8sEQGtSo&place_id=ChIJZ0z6RPN2HBUR1lRjAx8XZo8";
    
    
    fetch(url, {
        method : "OPTIONS",
        
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'
            }
    })
    .then((response) => {
      console.log(response)
        if (!response.ok) {
            throw new Error(response.error)
        }
        return response.json();
    })
 },
 /*select(){
   console.log('selected')
   this.selectedStreet = this.searchingvalue
 },*/
    /*select(value){
      this.selectedStreet = value;
      //console.log(this.selectedStreet)
    },*/
    handleMapClick(e){
      if(this.step === 2){
        this.order.lat = e.latLng.lat()
      this.order.long = e.latLng.lng()
      }
      if(this.step ===3){
        this.order.delivery_long = e.latLng.lng()
      this.order.delivery_lat = e.latLng.lat()
      }
      
    },
    handleMarkerDrag(e){
      
      if(this.step === 2){
        this.order.lat = e.latLng.lat()
      this.order.long = e.latLng.lng()
      }
      if(this.step ===3){
        this.order.delivery_long = e.latLng.lng()
      this.order.delivery_lat = e.latLng.lat()
      }
    },
    setType(el){
      this.order.vehicle_type = el

    },
    showModal() {
      this.$refs["my-modal"].show();
      
    },
    hideModal() {
      this.step = 1
      this.$refs["my-modal"].hide();
      this.client = ''
    },
    /*refresh(){
      if(this.toggled === true){
         window.location.reload()
        
      }
      
       

      
      

    },
    setToggle(){
      
      if(this.toggled === true){
        this.toggled = false;
        
        
        //setTimeout(this.refresh, 5000);

      }
      else if(this.toggled === false){
        this.toggled = true
       setTimeout(this.refresh, 5000);
        
      }
      

    }*/
  }
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}
.radioContainer{
  
  display: flex;
  justify-content: center;
}
.radio{
  padding: 20px;
  border: 2px solid rgba(128, 128, 128, 0.164);
 margin-left: 10px;
 margin-bottom: 30px;
 border-radius: 20px;
 
}

.radio:hover{
  cursor: pointer;
  filter: grayscale(50%);
  transition: filter linear 200ms;
 
}
.radio{
  filter: grayscale(100%);
  
}
.radioSel{
  padding: 20px;
  border: 2px solid rgb(127, 182, 255);
 margin-left: 10px;
 margin-bottom: 30px;
 border-radius: 20px;
 filter: grayscale(0%);
 transition: filter linear 200ms;
 
}
.cl:hover{
  color: rgb(130, 184, 255);
  cursor: pointer;
}
.labe{
  font-weight: 500;
}

.vs__dropdown-toggle{
  border: none !important;
}
#select .vue-select .dropdown-toggle{
  border: none;
}
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}

</style>